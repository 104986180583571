<template>
  <div>
    <b-card>
    <b-col cols="" class="d-flex">
      <div>
<!--        <b-form-group>-->
<!--          <b-input-group class="input-group-merge">-->
<!--            <b-input-group-prepend is-text>-->
<!--              <feather-icon icon="CalendarIcon"/>-->
<!--            </b-input-group-prepend>-->
<!--            <flat-pickr-->
<!--                v-model="date"-->
<!--                :config="{ mode:'range', maxDate: 'today',enableTime: false, noCalendar: false, dateFormat: 'Y-m-d'}"-->
<!--                class="form-control"-->
<!--                placeholder="Filter by date"-->
<!--            />-->
<!--          </b-input-group>-->
<!--        </b-form-group>-->
      </div>
    </b-col>
        <b-col cols="" class="d-flex">
             <div>
                <b-button-group>
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    >
                    Month
                    </b-button>
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    >
                    Year
                    </b-button>
                </b-button-group>
            </div>
            <div class="ml-2">
                <b-form-group>
                          <v-select
                            style="width: 210px;"
                            class="bg-white"
                            placeholder="This Week: Feb 22 - Feb 28"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title3"
                            :options="option"
                          />
                     </b-form-group>
            </div>
             <div class="ml-auto mb-1">
                <b-button
                    size="23"
                    variant="white"
                    v-b-tooltip.hover.top="'Download'"
                    class="btn-icon bg-white text-primary shadow-sm font-weight-bold mr-1" >
                    <feather-icon
                        size="18"
                        icon="DownloadIcon"
                    />
                </b-button>
            </div>
        </b-col>
    <b-col cols="12">
      <b-overlay
      :show="tableLoading"
      rounded="sm"
      >
      <b-table
          ref="table"
          :current-page="currentPage"
          :per-page="pagination.perPage"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          :items="getSubmittedTimesheet"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          hover
          class="mobile_table_css"
          responsive
      >

      <template #cell(time)="data">

        <div v-if="data.item.shift.start !==data.item.start || data.item.shift.end !==data.item.end" class="pt-3">
          <del >
          {{data.item.time}}
        </del>
        <br>
        <span class="alert alert-primary d-flex font-weight-bold" style="padding:5px">
          <span class=" text-dark"><feather-icon size="15" class="" icon="Edit2Icon"/>
        {{momentFormat(data.item.start,'HH:mm')}} - {{momentFormat(data.item.end,'HH:mm')}}  </span>
        </span>
        </div>
        <div v-else>
          {{data.item.time}}
        </div>

        </template>


        <template #cell(hours)="data">
          <div v-if="data.item.hours !== data.item.shift_duration" class="pt-3">
            <del>
              {{data.item.shift_duration}}
            </del>
            <br>
            <span class="alert alert-primary d-flex font-weight-bold" style="padding:5px">
                  <span class=" text-dark"><feather-icon size="15" class="" icon="Edit2Icon"/>
                {{data.item.hours}}
                  </span>
                </span>
          </div>
          <div v-else>
            {{data.item.hours}}
          </div>
        </template>

        <template #cell(commentary)="data">

            <b-row v-if="data.item.commentary" class="center text-primary ">
                <feather-icon v-b-tooltip.hover="data.item.commentary" size="20" class="ml-3" icon="MessageCircleIcon"/>
            </b-row>

        </template>

        <template #cell(status)="data">
             <b-badge v-if="data.item.status == 'Pending' || data.item.status == 'pending'" :variant="'light-warning'" class="text-capitalize" >
                 {{data.item.status}}
             </b-badge>
             <b-badge v-if="data.item.status == 'Approved' || data.item.status == 'approved'" :variant="'light-success'" class="text-capitalize" >
                 {{data.item.status}}
             </b-badge>
        </template>

      </b-table>
      <div class="text-center p-5" v-if="pagination.totalRows === 0 ">
        <svg width="220" height="220" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M80 0C124.183 0 160 35.817 160 80C160 124.183 124.183 160 80 160C35.817 160 0 124.183 0 80C0 35.817 35.817 0 80 0Z" fill="#F0F8FC"/>
        <path d="M66.1895 87.4937H92.1618M66.1895 100.484H83.5058" stroke="#1B9AAA" stroke-width="3" stroke-linecap="round"/>
        <path d="M81.3234 40.7456H54.3247C50.8315 40.7456 48 43.5763 48 47.0666V116.679C48 120.169 50.8315 123 54.3247 123H102.28C105.778 123 108.609 120.169 108.609 116.679V68.0303" stroke="#171822" stroke-width="3" stroke-linecap="round"/>
        <path d="M111.522 45.8106C111.888 46.9881 112.084 48.2401 112.084 49.5382C112.084 56.4629 106.47 62.0764 99.546 62.0764C92.6218 62.0764 87.0078 56.4629 87.0078 49.5382C87.0078 42.6135 92.6218 37 99.546 37C101.267 37 102.91 37.3472 104.403 37.9755" stroke="#171822" stroke-width="2.5" stroke-linecap="round"/>
        <path d="M109.825 42.3568C109.156 41.4007 108.356 40.5423 107.453 39.8071" stroke="#171822" stroke-width="2.5" stroke-linecap="round"/>
        <path d="M98.7554 43.0996V50.1572C98.7554 50.2105 98.7983 50.2536 98.8525 50.2536H104.855" stroke="#1B9AAA" stroke-width="2.5" stroke-linecap="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M137.872 55.3193C140.221 55.3193 142.127 57.2249 142.127 59.5747C142.127 61.9244 140.221 63.83 137.872 63.83C135.522 63.83 133.616 61.9244 133.616 59.5747C133.616 57.2249 135.522 55.3193 137.872 55.3193Z" fill="#DCEEF8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M149.788 41.7021C153.078 41.7021 155.745 44.3694 155.745 47.6596C155.745 50.9498 153.078 53.617 149.788 53.617C146.498 53.617 143.831 50.9498 143.831 47.6596C143.831 44.3694 146.498 41.7021 149.788 41.7021Z" fill="#DCEEF8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M130.212 40C131.153 40 131.914 40.7617 131.914 41.7021C131.914 42.6426 131.153 43.4043 130.212 43.4043C129.272 43.4043 128.51 42.6426 128.51 41.7021C128.51 40.7617 129.272 40 130.212 40Z" fill="#DCEEF8"/>
        </svg>
        <br>
        <div class="mt-lg-1 text-large">No Timesheets</div>
      </div>
      </b-overlay>
    </b-col>


    <!-- Pagination -->
    <b-row>
    <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
      <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
    </b-col>

    <!-- Pagination -->

    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" >

      <b-pagination
          v-model="currentPage"
          :per-page="pagination.perPage"
          :total-rows="pagination.totalRows"
          class="mb-0 mt-1 mt-sm-0 "
          first-number
          last-number
          next-class="next-item"
          prev-class="prev-item">

          <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>

          <template #next-text>
             <feather-icon icon="ChevronRightIcon" size="18" />
          </template>

      </b-pagination>

    </b-col>
    </b-row>
    </b-card>
  </div>
</template>

<script>

import {BDropdown, BDropdownItem} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component'
import MomentMixin from '@/mixins/MomentMixin'
import TimesheetAPI from '@/apis/modules/timesheet'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import DateDiff from 'date-diff'

import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    VBTooltip,
    BInputGroupPrepend,
    BButtonGroup,
    BOverlay,
    BCard

} from 'bootstrap-vue'

export default {
  components: {
    flatPickr,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BButtonGroup,
    BOverlay,
    vSelect,
    VBTooltip,
    BCard
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  data() {
    return {
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      totalRows: 1,
      currentPage: 1,
      submittedTableLenght:0,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      tableLoading: false,
      date: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'date',
          label: 'DATE',
          sortable: true
        },
        {
          key: 'time',
          label: 'TIME',

        },
        {
          key: 'break',
          label: 'BREAK',

        },
        {
          key: 'hours',
          label: 'HOURS',

        },
        {
          key: 'organization',
          label: 'ORGANIZATION',

        },
        {
          key: 'programme',
          label: 'PROGRAMME',
        },
        {
          key: 'commentary',
          label: 'Commentary',
        },
        {
          key: 'status',
          label: 'STATUS',
          sortable: true
        },

      ],

      option: [{title3: 'Feb 15 - Feb 21'},{title3: 'Feb 8 - Feb 2'},{title3: 'Feb 1 - Feb 7'},{title3: 'Jan 25 - Jan 31'},],

    }
  },
  methods:{
    async getSubmittedTimesheet(){
      try{
        this.tableLoading = true
        const Response = await TimesheetAPI.getSubmitted(this.currentPage, 15);

        const dataArray = Response.data.data.map((x)=>({
          id: x.id,
          date: `${this.momentFormat(x.start, 'DD-MM-YYYY')}`,
          start: x.start,
          end: x.end,
          shift:x.shift,
          time: `${this.momentFormat(x.shift.start, 'HH:mm')}` + '-' + `${this.momentFormat(x.shift.end, 'HH:mm')}`,
          organization: x.shift.pcn.name,
          hours: `${new DateDiff( new Date(x.end), new Date(x.start)).hours()}`,
          shift_duration : `${new DateDiff( new Date(x.shift.end), new Date(x.shift.start)).hours()}`,
          programme: x.shift.p_c_n_programme_id ? x.shift.pcn_programme.name : 'N/A',
          break: x.shift.break_minutes ? x.shift.break_minutes: 'N/A',
          status: x.state,
          commentary: x.commentary,
        }))


        this.noDataTable = Response.data.data.length
        this.submittedTableLenght = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to


        this.tableLoading = false

        return dataArray
      }catch(error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    }
  },
  mounted() {
    this.getSubmittedTimesheet()
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>

</style>
