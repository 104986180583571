<template>
  <div class="home">
    <div class="float-right">
      <b-row>
        <b-avatar variant="light-primary" rounded>
          <feather-icon icon="CalendarIcon" size="18"/>
        </b-avatar>

        <div class="ml-1 mr-3">
          <h4 class="mb-0 font-weight-bolder "> {{this.unSubmittedTotal}} </h4>
          <small>Unsubmitted timesheets</small>
        </div>

        <b-avatar variant="light-success" rounded>
          <feather-icon icon="ClockIcon" size="18"/>
        </b-avatar>

        <div class="ml-1 mr-3">
          <h4 class="mb-0 font-weight-bolder "> {{ this.totalHours}} </h4>
          <small>Total hours unsubmitted</small>
        </div>

<!--        <b-avatar variant="light-primary" rounded>-->
<!--          <feather-icon icon="CalendarIcon" size="18"/>-->
<!--        </b-avatar>-->

<!--        <div class="ml-1 mr-2">-->
<!--          <h4 class="mb-0 font-weight-bolder "> {{this.submittedTotal}} </h4>-->
<!--          <small>Submitted pending time sheets</small>-->
<!--        </div>-->
      </b-row>
      </div>


<!--    <div class="row ml-1">-->
<!--        <div class="d-flex align-items-center mr-3 mb-1">-->
<!--            <b-avatar variant="light-primary" rounded>-->
<!--                <feather-icon icon="CalendarIcon" size="18"/>-->
<!--            </b-avatar>-->

<!--            <div class="ml-1">-->
<!--                <h4 class="mb-0 font-weight-bolder "> {{this.unSubmittedTotal}} </h4>-->
<!--                <small>Unsubmitted timesheets</small>-->
<!--            </div>-->
<!--        </div>-->
<!--&lt;!&ndash;        <div class="d-flex align-items-center mr-3 mb-1">&ndash;&gt;-->
<!--            <b-avatar variant="light-success" rounded>-->
<!--                <feather-icon icon="ClockIcon" size="18"/>-->
<!--            </b-avatar>-->

<!--            <div class="ml-1">-->
<!--                <h4 class="mb-0 font-weight-bolder "> 43:00 </h4>-->
<!--                <small>Total hours unsubmitted</small>-->
<!--            </div>-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="d-flex align-items-center mr-3 mb-1">-->
<!--            <b-avatar variant="light-primary" rounded>-->
<!--                <feather-icon icon="CalendarIcon" size="18"/>-->
<!--            </b-avatar>-->

<!--            <div class="ml-1">-->
<!--                <h4 class="mb-0 font-weight-bolder "> {{this.submittedTotal}} </h4>-->
<!--                <small>Submitted pending time sheets</small>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <b-card class="mp-0">-->
      <b-tabs>
        <b-tab title="Unsubmitted" lazy>
          <div class="alert-body"><Unsubmitted
             @dataRefresh= "dataRefresh()"
             @summery = "updateSummery"
           /></div>
        </b-tab>
        <b-tab title="Submitted" lazy>
          <div class="alert-body"><Submitted /></div>
        </b-tab>
      </b-tabs>
<!--   </b-card>-->
 </div>
</template>

<script>
import Unsubmitted from './views/unsubmitted.vue'
import Submitted from './views/submitted.vue'

import { BCard, BTabs , BTab, BAvatar, BOverlay, BRow } from 'bootstrap-vue'
import TimesheetAPI from '@/apis/modules/timesheet'

export default {
  name: 'Home',
  components: {
    Unsubmitted,
    Submitted,
    BCard,
    BTabs,
    BTab,
    BAvatar,
    BOverlay,
    BRow
  },
  data() {
    return{
      unSubmittedTotal: 0,
      submittedTotal: 0,
      loading:false,
      totalHours:0
    }
  },
  methods:{
    async getSummary(){
      const Response = (await TimesheetAPI.getSummary()).data.data
      // this.unSubmittedTotal = Response.un_submitted_count
      this.submittedTotal = Response.summited_count
    },
    dataRefresh(){
      this.getSummary()
    },

    updateSummery(val) {
      this.unSubmittedTotal = val.total_unsubmits
      this.totalHours = val.total_unsubmit_hours
    }
  },
  mounted(){
    this.getSummary()
  },
}
</script>
