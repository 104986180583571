var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "timesheet-edit-sidebar",
      visible: _vm.isTimesheetEditSideBarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-timesheet-edit-side-bar-active", val)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
              },
              [
                _vm.timesheetData.start
                  ? _c("h4", { staticClass: "mb-0" }, [
                      _vm._v(
                        " Edit timesheet - " +
                          _vm._s(
                            _vm.momentFormat(
                              _vm.timesheetData.start,
                              "DD-MM-YYYY"
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c("validation-observer", { ref: "refFormObserver" }, [
              _c(
                "div",
                { staticClass: "p-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "alert alert-warning p-1 d-flex font-weight-bold"
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "text-warning ",
                        attrs: { icon: "AlertCircleIcon", size: "25" }
                      }),
                      _c("label", { staticClass: "ml-1 font-small-4 " }, [
                        _vm._v(
                          " All timesheets need to be approved by Practice Admin. To simplify this process, please leave commentary when changing start and/or end time."
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("label", [_vm._v("Start Time")]),
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "input-group-merge" },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    { attrs: { "is-text": "" } },
                                    [
                                      _c("feather-icon", {
                                        attrs: { icon: "ClockIcon" }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("flat-pickr", {
                                    staticClass: "form-control",
                                    attrs: {
                                      config: {
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i"
                                      },
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.startTime,
                                      callback: function($$v) {
                                        _vm.startTime = $$v
                                      },
                                      expression: "startTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("label", [_vm._v("End Time")]),
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "input-group-merge" },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    { attrs: { "is-text": "" } },
                                    [
                                      _c("feather-icon", {
                                        attrs: { icon: "ClockIcon" }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("flat-pickr", {
                                    staticClass: "form-control",
                                    attrs: {
                                      config: {
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i"
                                      },
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.endTime,
                                      callback: function($$v) {
                                        _vm.endTime = $$v
                                      },
                                      expression: "endTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.startTime !== _vm.startTimeBeforeUpdate ||
                  _vm.endTime !== _vm.endTimeBeforeUpdate
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-primary p-1 d-flex font-weight-bold"
                        },
                        [
                          _c("label", { staticClass: "ml-1 font-small-4 " }, [
                            _c("del", [
                              _vm._v(" " + _vm._s(_vm.timesheetData.time))
                            ])
                          ]),
                          _c(
                            "label",
                            {
                              staticClass: "ml-1 font-small-4 font-weight-bold"
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  "New time: " +
                                    _vm._s(_vm.startTime) +
                                    " - " +
                                    _vm._s(_vm.endTime)
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "mt-1", attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { value: true },
                              model: {
                                value: _vm.takenBreak,
                                callback: function($$v) {
                                  _vm.takenBreak = $$v
                                },
                                expression: "takenBreak"
                              }
                            },
                            [_vm._v(" I have taken my break ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "mt-1", attrs: { cols: "12" } },
                        [
                          _c("label", [_vm._v("Commentary")]),
                          _c("b-form-textarea", {
                            attrs: {
                              id: "textarea-default",
                              placeholder:
                                "Why are you changing the shift time?",
                              rows: "3"
                            },
                            model: {
                              value: _vm.commentary,
                              callback: function($$v) {
                                _vm.commentary = $$v
                              },
                              expression: "commentary"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex  mt-2" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(255, 255, 255, 0.15)",
                              expression: "'rgba(255, 255, 255, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          attrs: {
                            disabled: _vm.isActive,
                            type: "submit",
                            variant: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.updateTimesheet()
                            }
                          }
                        },
                        [_vm._v(" Save ")]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(186, 191, 199, 0.15)",
                              expression: "'rgba(186, 191, 199, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          staticClass: "text-primary ml-1 shadow",
                          attrs: { type: "button", variant: "outline-white" },
                          on: { click: hide }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }